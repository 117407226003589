import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { TServiceDetail } from '../../../../utils/contents/services-detail';
import LetUsHelpSection from './LetUsHelpSection';

interface HowCanWeHelpSectionProps {
  className?: string;
}

const StyledContainer = styled.section(() => [
  tw`relative flex flex-col items-center mb-12 md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto -mt-8`
]);

const Wrapper = styled.div(() => [tw`pt-6 mb-0 w-full sm:mb-12 xl:mb-0`]);

const HowCanWeHelpSection: React.FC<HowCanWeHelpSectionProps> = ({ className }) => {
  const [isHoveredService, setIsHoveredService] = useState<TServiceDetail | null>(null);

  return (
    <div>
      <StyledContainer className={className} id="help">
        <Wrapper>
          <LetUsHelpSection
            isHoveredService={isHoveredService}
            setIsHoveredService={setIsHoveredService}
          />
        </Wrapper>
      </StyledContainer>
    </div>
  );
};

export default HowCanWeHelpSection;
