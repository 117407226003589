import React from 'react';
import tw, { styled } from 'twin.macro';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Button, ButtonType } from '../../Common/Button';
import { SectionText } from '../../../utils/TextLabel';
import useOnMount from '../../../hooks/use-on-mount';

const Item = styled.div`${tw`grid grid-cols-1 grid-rows-2 md:grid-rows-1 md:grid-cols-2 overflow-hidden w-full h-[705px] md:h-[665px]`}`;
const ItemImageContainer = styled.div`${tw`overflow-hidden bg-orange`}`;
const ItemImage = styled.img`${tw`object-cover w-full h-full`}`;
const ItemDetailContainer = styled.div`${tw`overflow-hidden flex flex-col justify-evenly items-center p-3 md:p-10 bg-[#283941]`}`;
const ItemDetailTitle = styled.p`${tw`text-xl font-normal text-white truncate md:text-3xl`}`;
const ItemDetailDescription = styled.p`${tw`text-center text-white text-sm md:text-xl line-clamp-7 font-light w-[80%]`}`;
// const ItemDetailButtonsContainer = styled.div`${tw`grid grid-cols-2 gap-3`}`;

type queryData = {
  allStrapiOurWorks: {
    edges: {
      node: {
        CardImage: {
          localFile: {
            publicURL: string;
          };
        };
        Slug: string;
        Title: string;
        id: string;
        LongDesc: string;
        ShortDesc: string;
      };
    }[];
  };
};

const WhatWeHaveDoneSection = () => {
  const data = useStaticQuery<queryData>(graphql`
    query ourWorksQuery {
      allStrapiOurWorks(sort: { fields: [updated_at], order: DESC }, limit: 5) {
        edges {
          node {
            CardImage {
              localFile {
                publicURL
              }
            }
            Slug
            Title
            id
            LongDesc
            ShortDesc
            updated_at
          }
        }
      }
    }
  `);
  const isMount = useOnMount();
  const ourWorks = data.allStrapiOurWorks.edges;
  return (
    <>
      <SectionText className="mx-auto">What we've done</SectionText>
      <div className="mb-4 xl:mb-14 mt-14">
        {isMount && (
          <Swiper
            modules={[Navigation, Pagination]}
            navigation
            pagination={{
              clickable: true
            }}
            slidesPerView={1}
          >
            {ourWorks.map(({ node }) => {
              return (
                <SwiperSlide key={node.id}>
                  <Item>
                    <ItemImageContainer>
                      <ItemImage src={node.CardImage.localFile.publicURL} alt={node.Title} />
                    </ItemImageContainer>
                    <ItemDetailContainer>
                      <ItemDetailTitle>{node.Title}</ItemDetailTitle>
                      <ItemDetailDescription>{node.ShortDesc ?? node.LongDesc}</ItemDetailDescription>
                      <Button
                        type={ButtonType.WHITE_GHOST}
                        className="px-8 py-0 mx-auto text-white bg-opacity-0 border-white md:mr-auto"
                        onClick={() => navigate(`/work/${node.Slug}`)}
                      >
                        View more
                      </Button>
                      {/* <ItemDetailButtonsContainer>
                      </ItemDetailButtonsContainer> */}
                    </ItemDetailContainer>
                  </Item>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </>
  );
};

export default WhatWeHaveDoneSection;
