import * as React from 'react';
import tw, { styled } from 'twin.macro';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Button, ButtonType } from '../../Common/Button';

const StyledContainer = styled.section(() => [
  tw`flex flex-col items-center mb-12 md:mb-20 md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto`
]);

const Text = styled.p(() => [
  tw`max-w-5xl text-lg text-center justify-center text-[#283941] mx-8 leading-7 xl:leading-9 mb-10 xl:text-[1.45rem] xl:mb-14 sm:mb-6`
]);

const OrangeText = styled.span(() => [tw`text-[#E46B25] font-semibold`]);

const WhatWeOfferSection: React.FC = () => (
  <StyledContainer className="z-homeDetail pt-10">
    <Text className="z-homeDetail">
      “We offer services ranging from{' '}
      <OrangeText>
        Business Consulting, Technology Development, Early Business Operations and Digital Marketing.
      </OrangeText>{' '}
      In addition to that services, we develop <OrangeText>Software, Application and Website</OrangeText> that focus on
      the usablity of user.”
    </Text>
    <Button
      type={ButtonType.SECONDARY}
      onClick={() => scrollTo('#help')}
      className="px-8"
    >
      Do you want us to help?
    </Button>
  </StyledContainer>
);

export default WhatWeOfferSection;
