import React, { useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { FeatureData } from '../../../utils/contents/features-data';
import { SectionText } from '../../../utils/TextLabel';

const StyledContainer = styled.section(() => [
  tw`relative flex flex-col items-center md:max-w-pageContainerSize w-full px-0 mx-auto py-4 md:pt-10`
]);

const FeatureLists = styled.div(() => [tw`grid grid-cols-feature gap-9 bg-transparent pb-10 2xl:pb-16`]);

// const Feature = styled.div(() => [tw`flex flex-col justify-start p-6 bg-white shadow-md h-auto rounded-[10px]`]);

const Feature = styled.div`
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.85rem;
  padding-bottom: 1.85rem;
  height: auto;
`;

const Icon = styled.div(() => [
  tw`flex justify-center items-center w-[82px] h-[82px] rounded-full mx-auto`
]);

const IconImage = styled.img(() => [tw`w-[60px] h-[60px]`]);

const FeatureTitle = styled.h3(() => [tw`font-semibold text-spaceGray mb-5 text-lg md:text-xl text-center mx-auto`]);

const FeatureDetail = styled.p(() => [tw`text-[16px] mb-0 font-light`]);

const Column = styled.div(() => [tw`relative w-full overflow-x-scroll flex justify-start md:justify-start mt-6`]);

const CircleContainer = styled.div(() => [tw`absolute bottom-0 md:bottom-0 flex lg:hidden`]);

const Circle = styled.div<{ active: boolean; }>`
  ${tw`w-2 h-2 md:w-3 md:h-3 rounded-full m-[2.5px] md:m-1`};
  ${({ active }) => (active ? `background: #E46B25` : 'background: #afafaf')};
`;

const WhatCanWeDoSection: React.FC = () => {
  const refs = useRef<any>();
  const outerBoxRefs = useRef<any>();
  const [step, setStep] = useState(1);

  const onScroll = () => {
    const RowLength = refs.current.offsetWidth;
    const OuterBoxLength = outerBoxRefs.current.offsetWidth + 80;
    const NoOfCompany = FeatureData.length;
    const OneSectionLength = (OuterBoxLength - RowLength) / NoOfCompany;
    const currentScrollLeft = refs.current.scrollLeft;
    const currentStep =
      Math.ceil(currentScrollLeft / OneSectionLength) === 0 ? 1 : Math.ceil(currentScrollLeft / OneSectionLength);
    setStep(currentStep);
  };

  return (
    <StyledContainer className="z-homeDetail">
      <SectionText>What we are good at</SectionText>
      {/* <div className="p-2 flex mt-10">
        <div className="w-max grid grid-cols-4 gap-9">
          {FeatureData.map((feature) => (
            <div
              key={feature.title}
              className="w-64 all-sides-shadow rounded-md bg-white flex flex-col p-5"
            >
              <Icon>
                <IconImage src={feature.icon} />
              </Icon>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDetail>{feature.detail}</FeatureDetail>
            </div>
          ))}
        </div>
      </div> */}
      {/* <div className="all-sides-shadow rounded-md bg-white">test</div> */}
      <Column id="scrollbar-hidden" ref={refs} onScroll={onScroll}>
        <div className="w-pageContainerSpace xl:w-0 flex-shrink-0" />
        <FeatureLists id="journal-scroll" ref={outerBoxRefs} className="p-2">
          {FeatureData.map((data: any) => (
            <Feature className="all-sides-shadow" key={data.title}>
              <Icon>
                <IconImage src={data.icon} />
              </Icon>
              <FeatureTitle>{data.title}</FeatureTitle>
              <FeatureDetail>{data.detail}</FeatureDetail>
            </Feature>
          ))}
        </FeatureLists>
        <div className="w-pageContainerSpace xl:w-0 flex-shrink-0" />
      </Column>
      <CircleContainer>
        {FeatureData &&
          FeatureData?.map((items: any, index: number) => <Circle active={index === step - 1} key={items.title} />)}
      </CircleContainer>
    </StyledContainer>
  );
};

export default WhatCanWeDoSection;
