import * as React from 'react';
import tw, { styled } from 'twin.macro';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Button, ButtonType } from '../../Common/Button';
import WorkEnv1 from '../../../assets/pages/index/WorkEnv1.jpg';
import WorkEnv2 from '../../../assets/pages/index/WorkEnv2.jpg';
import WorkEnv3 from '../../../assets/pages/index/WorkEnv3.jpg';
import WorkEnv4 from '../../../assets/pages/index/WorkEnv4.jpg';

const Image = styled.img`
  ${tw`w-full h-full object-cover`}
`;

const Background: React.FC = () => {
  return (
    <div className="w-full h-full absolute top-0 z-0">
      <div className="carousel-4 w-full h-full">
        <Image src={WorkEnv3} alt="Work Environment" />
        <Image src={WorkEnv1} alt="Work Environment" />
        <Image src={WorkEnv2} alt="Work Environment" />
        <Image src={WorkEnv4} alt="Work Environment" />
      </div>
    </div>
  );
};

const BannerSection: React.FC = () => (
  <div className="relative h-[500px] md:h-[620px] xl:h-[750px] 2xl:h-[820px]">
    <Background />
    <div className="absolute w-full h-full bg-black opacity-30 z-10" />
    <div className="w-full h-full flex flex-col justify-center absolute z-10 top-0 pt-10">
      <div className="w-[90%] xl:w-pageContainerSize h-full mx-auto flex flex-col justify-center mt-8 md:mt-18 gap-y-4 lg:gap-y-6">
        <h1 className="font-semibold capitalize text-3xl md:text-4xl xl:text-[3.2rem] xl:leading-[3.9rem] drop-shadow-md text-white my-0">
          An innovative Cap <br />
          for your success
        </h1>
        <h2 className="font-light text-base md:text-lg xl:text-xl text-white my-0 lg:-mt-4 drop-shadow-sm">
          Providing new business development services <br />
          focusing on the use of technology
        </h2>
        <Button
          onClick={() => scrollTo('#help')}
          className="w-[250px] mt-[2px]"
          type={ButtonType.PRIMARY}
        >
          Tell us your idea
        </Button>
      </div>
    </div>
  </div>
);

export default BannerSection;
