import React from 'react';
import Page from '../components/Common/Page';
import BannerSection from '../components/pages/IndexPage/BannerSection';
import IndexLayout from '../layouts';
import WhatCanWeDoSection from '../components/pages/IndexPage/WhatCanWeDoSection';
import WhatWeOfferSection from '../components/pages/IndexPage/WhatWeOfferSection';
import WhatWeHaveDoneSection from '../components/pages/IndexPage/WhatWeHaveDoneSection';
import HowCanWeHelpSection from '../components/pages/IndexPage/HowCanWeHelpSection';
import OurSuccessSection from '../components/pages/IndexPage/OurSuccessSection';
import OurClientSection from '../components/pages/IndexPage/OurClientSection';
import WorkWithUsSection from '../components/pages/IndexPage/WorkWithUsSection';

const IndexPage = () => {
  return (
    <IndexLayout transparent={false} mode="fixed-faded">
      <Page>
        <BannerSection />
        <WhatCanWeDoSection />
        <WhatWeOfferSection />
        <WhatWeHaveDoneSection />
        <HowCanWeHelpSection />
        <OurSuccessSection />
        <OurClientSection />
        <WorkWithUsSection />
      </Page>
    </IndexLayout>
  );
};

export default IndexPage;
