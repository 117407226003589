import React, { useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { SwiperSlide } from 'swiper/react/swiper-react';
import BorderImage from '../../../assets/pages/index/svgs/border.svg';
import { SectionText } from '../../../utils/TextLabel';
import ResponsiveSwiper from '../../Common/ResponsiveSwiper';
import useOnMount from '../../../hooks/use-on-mount';

interface TSuccess {
  node: {
    Content: string;
    Logo: {
      localFile: {
        publicURL: string;
      };
    };
    Presenter: string;
    WebsiteUrl: string;
    Year: string;
  };
}
interface TStrapiAwards {
  allStrapiAwardsAndTestimonials: {
    edges: TSuccess[];
  };
}

const StyledContainer = styled.section(() => [tw`relative flex flex-col items-center mx-auto mb-16 md:mb-18`]);

const Column = styled.div(() => [
  tw`relative flex justify-start w-full overflow-x-scroll xl:justify-center md:max-w-pageContainerSize px-pageContainerSpace xl:px-0`
]);

const Box = styled.div(() => [tw`w-[180px] md:w-[288px] flex flex-col items-center`]);

const Title = styled.p(() => [
  tw`w-full text-center text-[20px] lg:text-[32px] font-bold text-[#283941] mb-10 md:mb-20`
]);

const Year = styled.p(() => [tw`text-black text-[12px] font-bold mt-[9px] md:mt-[18px] md:text-2xl`]);

const Content = styled.p(() => [
  tw`text-spaceGray font-bold text-[8px] leading-[10px] md:text-[16px] md:leading-6 w-4/5 md:w-[185px] text-center mt-[9px] md:mt-[18px]`
]);

const Line = styled.img(() => [tw`w-full`]);

const CircleContainer = styled.div(() => [tw`absolute bottom-0 md:bottom-[-40px] flex xl:hidden`]);

const Circle = styled.div<{ active: boolean; }>`
  ${tw`w-2 h-2 md:w-3 md:h-3 rounded-full m-[2.5px] md:m-1`};
  ${({ active }) => (active ? `background: #E46B25` : 'background: #afafaf')};
`;

interface OurSuccessSectionProps {
  className?: string;
}

const OurSuccessSection: React.FC<OurSuccessSectionProps> = ({ className }) => {
  const refs = useRef<any>();
  const outerBoxRefs = useRef<any>();
  const [step, setStep] = useState(1);
  const data: TStrapiAwards = useStaticQuery(graphql`
    query OurSuccessSectionQuery {
      allStrapiAwardsAndTestimonials {
        edges {
          node {
            Content
            Presenter
            Logo {
              localFile {
                publicURL
              }
            }
            Year
            WebsiteUrl
          }
        }
      }
    }
  `);
  const isMount = useOnMount();
  const onScroll = () => {
    const RowLength = refs.current.offsetWidth;
    const OuterBoxLength = outerBoxRefs.current.offsetWidth + 80;
    const NoOfCompany = data?.allStrapiAwardsAndTestimonials?.edges?.length;
    const OneSectionLength = (OuterBoxLength - RowLength) / NoOfCompany;
    const currentScrollLeft = refs.current.scrollLeft;
    const currentStep =
      Math.ceil(currentScrollLeft / OneSectionLength) === 0 ? 1 : Math.ceil(currentScrollLeft / OneSectionLength);
    setStep(currentStep);
  };

  return (
    <StyledContainer>
      <div
        className="w-[1200px] flex flex-col items-center pt-10 pb-20 mt-10"
        style={{
          backgroundImage: 'radial-gradient(#DADADA 15%, transparent 10%), radial-gradient(#DADADA 15%, transparent 10%)',
          backgroundColor: '#FFF',
          backgroundPosition: '0px 30px, 40px 30px',
          backgroundSize: '40px 40px',
        }}
      >
        <SectionText className="mt-[1.95rem]">Our success stories</SectionText>
        <div className="flex flex-col items-center justify-center w-full pt-8 pb-4 mt-20 bg-white md:py-8">
          {isMount && (
            <ResponsiveSwiper>
              {data && data.allStrapiAwardsAndTestimonials.edges
                .sort((a, b) => Number(a.node.Year) - Number(b.node.Year))
                .map((item) => (
                  <SwiperSlide key={item.node.Content} className="mt-4 mb-16">
                    <div className="flex flex-col items-center w-full gap-y-3 text-spaceGray">
                      <span className="text-2xl font-semibold">{item.node.Year}</span>
                      <div className="w-9 h-[3px] bg-orange" />
                      <div className="w-[150px] h-[70px] mt-2 flex flex-col items-center justify-center">
                        <img
                          alt={`Award ${item.node.Year}`}
                          src={item.node.Logo.localFile.publicURL}
                          // className="object-cover w-auto mb-2 h-7 md:h-10"
                          className="object-contain w-full h-full mb-2"
                        />
                      </div>
                      <span className="text-base text-center font-light w-[220px]">{item.node.Content}</span>
                    </div>
                  </SwiperSlide>
                ))}
            </ResponsiveSwiper>
          )}
          {/* <div className="relative flex w-full overflow-x-auto md:px-56 lg:px-24 xl:px-0">
            <Swiper
              modules={[Navigation, Pagination]}
              pagination={{ clickable: true }}
              navigation
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 4,
                }
              }}
            >
              {data && data.allStrapiAwardsAndTestimonials.edges
                .sort((a, b) => Number(a.node.Year) - Number(b.node.Year))
                .map((item) => (
                  <SwiperSlide key={item.node.Content} className="mt-4 mb-16">
                    <div className="flex flex-col items-center w-full gap-y-3 text-spaceGray">
                      <span className="text-2xl font-semibold">{item.node.Year}</span>
                      <div className="w-9 h-[3px] bg-orange" />
                      <div className="w-[150px] h-[70px] mt-2 flex flex-col items-center justify-center">
                        <img
                          alt={`Award ${item.node.Year}`}
                          src={item.node.Logo.localFile.publicURL}
                          // className="object-cover w-auto mb-2 h-7 md:h-10"
                          className="object-contain w-full h-full mb-2"
                        />
                      </div>
                      <span className="text-base text-center font-light w-[220px]">{item.node.Content}</span>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div> */}
          {/* <Column id="scrollbar-hidden" ref={refs} onScroll={onScroll}>
            <div className="grid w-full grid-cols-4 gap-10" ref={outerBoxRefs}>
              {data &&
                data.allStrapiAwardsAndTestimonials.edges
                  .sort((a, b) => Number(a.node.Year) - Number(b.node.Year))
                  .map((item) => (
                    <div key={item.node.Content} className="flex flex-col items-center w-full gap-y-3 text-spaceGray">
                      <span className="text-2xl font-semibold">{item.node.Year}</span>
                      <div className="w-9 h-[3px] bg-orange" />
                      <img
                        alt={`Award ${item.node.Year}`}
                        src={item.node.Logo.localFile.publicURL}
                        className="object-cover w-auto mb-2 h-7 md:h-10"
                      />
                      <span className="mx-auto text-lg text-center">{item.node.Content}</span>
                    </div>
                    // <Box key={item.node?.Content}>
                    //   <img
                    //     className="w-auto mb-6 cursor-pointer h-7 md:h-10"
                    //     src={item.node?.Logo?.localFile?.publicURL}
                    //     alt="Award"
                    //     onClick={() => navigate(item.node?.WebsiteUrl)}
                    //   />
                    //   <Line src={BorderImage} />
                    //   <Year>{item.node?.Year}</Year> // this line is comment
                    //   <Content>{item.node?.Content}</Content>
                    // </Box>
                  ))
              }
            </div>
          </Column> */}
        </div>
      </div>
    </StyledContainer>
  );

  // return (
  //   <StyledContainer className={className}>
  //     <Title>Our success</Title>
  //     <Column id="scrollbar-hidden" ref={refs} onScroll={onScroll}>
  //       <OuterBox ref={outerBoxRefs}>
  //         {data &&
  //           data?.allStrapiAwardsAndTestimonials?.edges
  //             .sort((a: any, b: any) => a?.node?.Year - b?.node?.Year)
  //             .map((items: any) => (
  //               <Box key={items?.node?.Content}>
  //                 <img
  //                   className="w-auto mb-6 cursor-pointer h-7 md:h-10"
  //                   src={items?.node?.Logo?.localFile?.publicURL}
  //                   alt="Award"
  //                   onClick={() => navigate(items?.node?.WebsiteUrl)}
  //                 />
  //                 <Line src={BorderImage} />
  //                 {/* <Year>{items?.node?.Year}</Year> */}
  //                 <Content>{items?.node?.Content}</Content>
  //               </Box>
  //             ))}
  //       </OuterBox>
  //     </Column>
  //     <CircleContainer>
  //       {data &&
  //         data?.allStrapiAwardsAndTestimonials?.edges.map((items: any, index: number) => (
  //           <Circle active={index === step - 1} key={items?.node?.Content} />
  //         ))}
  //     </CircleContainer>
  //   </StyledContainer>
  // );
};
export default OurSuccessSection;
