import Feature1 from '../../assets/pages/index/svgs/Feature1.svg';
import Feature2 from '../../assets/pages/index/svgs/Feature2.svg';
import Feature3 from '../../assets/pages/index/svgs/Feature3.svg';
import Feature4 from '../../assets/pages/index/svgs/Feature4.svg';

interface TFeature {
  icon: any;
  title: string;
  detail: string;
};

export const FeatureData: TFeature[] = [
  {
    icon: Feature1,
    title: 'Build New Innovative Business',
    detail:
      'Business Model & Business Strategy Analysis, Business Development, Operation Set up and Product Launch Planning'
  },
  {
    icon: Feature2,
    title: 'Create Tailor-made Solution',
    detail: 'Solution Consultant, User Experience (UX) Study & Design, Process Optimization, Product Development'
  },
  {
    icon: Feature3,
    title: 'Build Software/Website',
    detail:
      'User Interface Design for Mobile Application (iOS & Android), Web Application, Software Development , UAT/SIT and Maintenance'
  },
  {
    icon: Feature4,
    title: 'Provide Marketing Integration',
    detail: 'Online and Social Media Advertisting, Press Release, Influencer Marketing and Digital Film and Animation'
  }
];