import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { navigate } from 'gatsby';
import { Button, ButtonType } from '../../Common/Button';
import WorkWithUs from '../../../assets/pages/index/WorkWithUs.jpg';
import OCILogo from '../../../assets/logo/OCI_color.svg';

const WorkWithUsImage = styled.div<{ src: string; }>`
  ${tw`w-screen md:h-[600px] lg:h-[700px] flex justify-center items-center !bg-cover !bg-center`};
  ${({ src }) => src && `background: url(${src})`};
`;

const WorkWithUsSection: React.FC = () => {
  return (
    <div className="mt-20 w-full relative hidden md:flex flex-col">
      <span className="text-orange text-[26px] text-center font-bold mx-auto">Let's talk with us</span>
      <span className="text-spaceGray mx-auto text-center text-xl mt-2">Get start and grow your bussiness, We're here for you!</span>
      <img
        alt="Logo - Work with us section"
        src={OCILogo}
        className="w-24 h-24 mx-auto -mt-2"
      />
      <WorkWithUsImage className="mt-10" src={WorkWithUs}>
        {/* <div className="absolute t-0 l-0 w-full h-full bg-black opacity-40" /> */}
        <Button
          type={ButtonType.WHITE_GHOST}
          onClick={() => navigate('/ourteam')}
          className="w-[220px]"
        >
          Work with us
        </Button>
      </WorkWithUsImage>
    </div>
  );
};

export default WorkWithUsSection;
